import React, { useContext } from "react";
import Pitchright_Portfolio from "./pitchright-portfolio";
import PortfolioList from './portfolio_list';
import { portfolioContext } from '../pages/portfolio'
import TeamPortfolioList from "./team_portfolio";


const Tabs_Portfolio = () => {
    const [openTab, setOpenTab] = React.useState(1);
    const portfolioData = useContext(portfolioContext).allPortfolios.portfolios

    const pitchRightData = portfolioData.filter((portfolio) => { return portfolio.type === "PITCHRIGHT" })

    const othersData = portfolioData.filter((portfolio) => { return portfolio.type === "OTHERS" })

    return (
        <>
            <div className="relative ">
                <PortfolioList data={pitchRightData} />
                {/* <div className=" rounded-3xl flex justify-center mx-auto  w-60v  ">
                    <ul
                        className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                        role="tablist"
                    >
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            <a
                                className={
                                    "text-sm font-bold  px-8 py-3 block leading-relaxed text-black border-b-2 border-white " +
                                    (openTab === 1
                                        ? " border-orange-primary"
                                        : " bg-sky-50")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(1);
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >
                                Fund Portfolio
                            </a>
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            <a
                                className={
                                    "text-sm font-bold  px-8 py-3 block leading-relaxed text-black border-b-2 border-white " +
                                    (openTab === 2
                                        ? " border-orange-primary"
                                        : " bg-sky-50")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(2);
                                }}
                                data-toggle="tab"
                                href="#link2"
                                role="tablist"
                            >
                                Team Portfolio
                            </a>
                        </li>
                    </ul>
                </div>
                <div className=" ">
                    <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                        <p>
                            <PortfolioList data={pitchRightData} />
                        </p>
                    </div>
                    <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                        <TeamPortfolioList data={othersData} />
                    </div>
                </div> */}


            </div>
        </>
    );
};

export default function TabsRender() {
    return (
        <>
            <Tabs_Portfolio />;
        </>
    );
}