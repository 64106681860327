import React, { useState } from "react";
var slugify = require("slugify");

export default function PortfolioList(portfolioData) {
  return (
    <section>
      <div class="mt-14 flex flex-wrap justify-center my-4 px-4">
        <div class="flex flex-wrap w-11/12  md:w-11/12  lg:w-10/12 ">
          {portfolioData.data.map((portfolio) => (
            <div class="mb-16 w-full sm:w-1/2  md:w-1/3 lg:w-1/4 px-3">
              <div class="rounded-3xl flex flex-col justify-center overflow-hidden shadow-xl bg-white">
                {portfolio.website_link !==
                "https://www.pitchright.ventures/portfolio" ? (
                  <a
                    href={portfolio.website_link}
                    target="_blank"
                    className="flex justify-center"
                  >
                    <img
                      class="object-contain w-1/2 h-32 md:h-32 lg:w-2/3 lg:h-36"
                      src={portfolio.logo_thumbnail}
                      alt=""
                    />
                  </a>
                ) : (
                  <div className="flex justify-center">
                    <img
                      class="object-contain w-1/2 h-32 md:h-32 lg:w-2/3 lg:h-36"
                      src={portfolio.logo_thumbnail}
                      alt=""
                    />
                  </div>
                )}

                {portfolio.website_link !==
                "https://www.pitchright.ventures/portfolio" ? (
                  <a
                    href={portfolio.website_link}
                    target="_blank"
                    className="flex flex-col justify-center"
                  >
                    <div class="p-6 h-40 bg-gray-900 text-center flex flex-col justify-center items-center">
                      <p className="text-sm text-gray-300">
                        {portfolio.short_description}
                      </p>
                    </div>
                  </a>
                ) : (
                  <div class="p-6 h-40 bg-gray-900 text-center flex flex-col justify-center items-center">
                    <p className="text-sm text-gray-300">
                      {portfolio.short_description}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
