import React from 'react';
import Tabs_Portfolio from './tabs-portfolio'
import PortfolioHero from './portfolio_hero'


export default function Portfolio({ }) {

    return (
        <>

            <PortfolioHero />

            <Tabs_Portfolio />

        </>
    );
};