import React from 'react';
export default function PortfolioHero({ location, data }) {
    return (
        <section className="relative ">
            <div className="h-50v ls:h-70v bg-primary-blue"></div>
            <div>
                <svg viewBox="0 0 1440 285" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1440 -2V34.2881V34.2881C1426.13 110.841 1362.67 168.598 1285.15 175.22L0 285V-2H1440Z" fill="#0F4D92" />
                </svg>
            </div>
            <div className="absolute top-25% inset-x-0">
                    <div className="flex flex-col justify-center items-center px-15% content-center ">
                    <h1 className="font-banner-heading">
                             <span >Meet the</span>
                            <span className="inline   text-gray-200 xl:inline"> <div className=" inline text-orange-primary js-scroll js-animate-fade-in-up-text mt-2 "> Disruptors </div> </span>

                        </h1>
                        <div className=" w-full   mt-12 font-banner-body2 px-2 md:px-10">
 <p className=" block leading-normal md:leading-loose">  Companies changing the game through technology and creating a new game through innovation and sustainable value creation                       </p>
                        <p> <span className="text-orange2"></span> </p>
                             
                         </div>
                    </div>
  
                </div>
                <div className="invisible md:visible absolute top-32 left-4 h-20v w-20v" aria-hidden="true">
                <svg
                    className="relative    "
                    fill="none"
                    viewBox="0 0 404 150"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-indigo-500" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={400} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                </svg>

            </div>
        </section>

    )
}