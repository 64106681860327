import React from 'react';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Portfolio from '../components/portfolio';

export const portfolioContext = React.createContext()


export default function PeoplePage({ location, data }) {

  return (
    <Layout location={location}>
      <portfolioContext.Provider value={data} >
        <Portfolio />
      </portfolioContext.Provider>

    </Layout>
  )
}

export const fetchAllPorfoliosQuery = graphql`
{
  allPortfolios {
    portfolios(where: { type: "PITCHRIGHT" },sort: "priority") {
      id
      name
      short_description
      long_description
      type
      logo_thumbnail
      founders
      website_link
    }
  }
}
`;