import React, { useState } from 'react';
var slugify = require('slugify')

export default function TeamPortfolioList(portfolioData) {

    return (
        <section>
            <div className="flex flex-wrap justify-center items-center   my-16">
                <div class="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3  ">
                    {portfolioData.data.map((portfolio) => (

                        <div className="flip-card m-8 rounded-3xl ">
                            <div className="flip-card-inner rounded-3xl">
                                <div className="flip-card-front rounded-3xl">
                                    <img className="rounded-3xl shadow-2xl border-2 border-orange-300" src={portfolio.logo_thumbnail} alt="Avatar" style={{ width: '600px', height: '300px' }} />
                                </div>
                                <div className="flip-card-back px-8 rounded-3xl flex flex-col justify-center items-center">
                                    <h2 className="my-2 text-2xl font-bold text-white">{portfolio.name}</h2>
                                    <p className="text-sm text-gray-100">{portfolio.short_description}</p>
                                    {/* <p className="text-sm text-gray-100 mt-8">{portfolio.founders}</p> */}
                                    <a class="text-orange-primary hover:text-sky-50 font-bold mt-4" href={portfolio.website_link}><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};